import React, { useState, useEffect } from "react";
import RenderHtml from "./RenderHtml";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import './../styles/NewsApiListing.scss';

function NewsApiListing() {
  const [articles, setArticles] = useState([]);
  const [expandedArticle, setExpandedArticle] = useState(null); // To track the expanded article
  const [error, setError] = useState(null);

  //To display skeleton:
  const [loading, setLoading] = useState(true); // Add loading state

  const [numberOfArticlesTotal, setNumberOfArticlesTotal] = useState(0);
  const numberOfArticlesToDisplay = 8;
  const [articlesLoaded, setArticlesLoaded] = useState(numberOfArticlesToDisplay);

  // useEffect(() => {
  //   setLoading(true); //Set loading to true before fetching (skeleton)
  //   // Fetch data from the API
  //   axios
  //     .get("https://www.fagforbundet.no/api/articlelist/279548")
  //     .then((response) => {
  //       if (response.data){
  //         setNumberOfArticlesTotal(response.data.length);

  //         //Sorted articles:
  //         const sortedArticles = response.data.sort((a, b) => {
  //           const dateA = new Date(a.createdDate);
  //           const dateB = new Date(b.createdDate);
  //           return dateB - dateA; // Newest first
  //         });

  //         // Save the articles in state, limit to 20 elements
  //         setArticles(sortedArticles.slice(0, articlesLoaded));
  //       }
  //     })
  //     .catch((error) => {
  //       // Handle errors
  //       setError(error.message);
  //     })
  //     .finally(() => {
  //       setLoading(false); // Set loading to false once fetching is complete
  //     });
  // }, [articlesLoaded]);

  const fetchArticles = async () => {
    setLoading(true);
  
    try {
      // localStorage.removeItem("cachedArticles");
      // localStorage.removeItem("articlesExpiration");

      const cachedData = localStorage.getItem("cachedArticles");
      const expiration = localStorage.getItem("articlesExpiration");
  
      if (cachedData && expiration && Date.now() < parseInt(expiration, 10)) {
        // Use cached data if it hasn't expired
        const parsedData = JSON.parse(cachedData);
        setNumberOfArticlesTotal(parsedData.length);
        setArticles(parsedData.slice(0, articlesLoaded));
        setLoading(false);
        return;
      }
  
      // Fetch from the API if no cache is found or it has expired
      const response = await axios.get("https://www.fagforbundet.no/api/articlelist/279548");
      if (response.data) {
        const sortedArticles = response.data.sort((a, b) => {
          const dateA = new Date(a.createdDate);
          const dateB = new Date(b.createdDate);
          return dateB - dateA; // Newest first
        });
  
        // Cache the data in localStorage with an expiration time (e.g., 5 minutes)
        localStorage.setItem("cachedArticles", JSON.stringify(sortedArticles));
        localStorage.setItem("articlesExpiration", Date.now() + 1000 * 60 * 5); // 5 minutes
  
        setNumberOfArticlesTotal(sortedArticles.length);
        setArticles(sortedArticles.slice(0, articlesLoaded));
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchArticles();
  }, [articlesLoaded]);

  // Render an error message if there's an error
  if (error) {
    return <div>Error: {error}</div>;
  }

  const convertDate = (date) => {
    // Create a new Date object from the input string
    const dateObj = new Date(date);
  
    // Extract the components
    const day = dateObj.getUTCDate(); // Day of the month
    const month = dateObj.toLocaleString('nb-NO', { month: 'short' }); // Full month name
    const year = dateObj.getUTCFullYear(); // Full year

    // Format the date as "10 apr 2023"
    return `${day} ${month} ${year}`;
  };

  const handleArticleClick = (index) => {
    // Toggle expanded state for the clicked article
    if (expandedArticle == null) {
      setExpandedArticle((prev) => (prev === index ? null : index));
    }
  };

  const handleCloseButtonClick = (index) => {
    // Toggle expanded state for the clicked article
    if (expandedArticle !== null) {
      setExpandedArticle((prev) => (prev === index ? null : index));
    }
  };

  const loadMoreArticles = () => {
    setArticlesLoaded((prev) => prev + numberOfArticlesToDisplay);
  };

  return (
    <div className={`news-articles${expandedArticle !== null ? ' expanded' : ''}`}>
      {loading ? (
        <div className="skeleton-loader">
          <p className="display-status">Laster inn artikler..</p>
          {Array.from({ length: articlesLoaded }).map((_, index) => (
            <div className="skeleton-article" key={index}>
              <Skeleton height={150} width={1300} className="skeleton-image" />
            </div>
          ))}
        </div>
      ) : (
        <>
          {expandedArticle == null && (
            <p className="display-status">
              Viser {articlesLoaded}/{numberOfArticlesTotal} artikler
              <span className="display-status-information">PS: Data lagres i localstorage i 5 minutter</span>
            </p>
          )}

          {articles.map((article, index) => {
            const isExpanded = expandedArticle === index;

            // Conditionally render only the expanded article or all articles
            if (expandedArticle !== null && !isExpanded) {
              return null; // Hide all other articles if one is expanded
            }

            return (
              <article
                className={`article ${isExpanded ? 'expanded' : ''}`}
                key={index}
                onClick={() => handleArticleClick(index)}
              >
                {isExpanded && (
                  <button type="button" class="expanded-article-close" aria-label="Close" onClick={() => handleCloseButtonClick(index)}></button>
                )}

                {!isExpanded && (
                  <div className="article-lc">
                    {article.PreambleImageUrl && <img src={article.PreambleImageUrl} alt="Ingressbilde" />}
                  </div>
                )}

                <div className="article-rc">
                  <p className="title">{article.Title}</p>

                  {isExpanded && (
                    <div className="article-lc">
                    {article.PreambleImageUrl && (
                      <img src={article.PreambleImageUrl} alt="Hovedbilde" />
                    )}
                  </div>
                  )}

                  <p className="preamble">{article.Preamble}</p>
                  <p className="author-container">
                    <span className="date">{convertDate(article.createdDate)}</span>
                    <span> av </span>
                    <span className="author">{article.Author}</span>
                  </p>

                  {isExpanded && (
                    <>
                      <div className="main-text">
                        <RenderHtml content={article.MainText} />
                      </div>
                      <p className="user-select-all bottom-article-text">{article.ContentLink}</p>
                    </>
                  )}

                  {!isExpanded && (
                    <span className="animation-finger-click"></span>
                  )}
                </div>
              </article>
            );
          })}

          {expandedArticle == null && (
            <p className="load-more-btn" onClick={loadMoreArticles}>
              Last inn flere..
            </p>
          )}
      </>
    )}
    </div>
  );

}

export default NewsApiListing;