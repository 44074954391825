import './App.scss';
import TextInput from './components/TextInput';
import Button from './components/Button';
import NewsApiListing from './components/NewsApiListing';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Fagforbundet Nyheter</h1>
      </header>
      <main>
        <NewsApiListing />
      </main>
    </div>
  );
}

export default App;
