import React, { useEffect } from "react";

function RenderHtml({ content }) {
  useEffect(() => {
    const figures = document.querySelectorAll(".ff-tinymce-figure");
    figures.forEach((figure) => {
      const img = figure.querySelector("img");
      if (img) {
        // Set the figure's width to match the image's width
        figure.style.width = `${img.offsetWidth}px`;
      }
    });
  }, [content]); // Runs every time the content changes

  return (
    <div
      className="render-html"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
}


export default RenderHtml;